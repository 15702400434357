import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from './LoadingSpinner';

const PlantDetails = () => {
  const { plantId } = useParams();
  const navigate = useNavigate();
  const [plant, setPlant] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlant = async () => {
      const plantDoc = doc(db, 'plants', plantId);
      const docSnap = await getDoc(plantDoc);
      if (docSnap.exists()) {
        setPlant(docSnap.data());
      } else {
        console.log('No such document!');
      }
      setLoading(false);
    };

    fetchPlant();
  }, [plantId]);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  if (loading) {
    return <LoadingSpinner />;
  }

  const { name, image, description, health, wateringReminders } = plant;

  return (
    <div className="p-4 bg-green-100 min-h-screen relative">
      <button
        onClick={() => navigate('/dashboard')}
        className="absolute top-4 left-4 flex items-center text-lg bg-white rounded px-2 py-1 shadow"
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
        Back
      </button>
      <button
        onClick={() => navigate(`/edit-plant/${plantId}`)} // Navigate to the edit page
        className="absolute top-4 right-4 flex items-center text-lg bg-white rounded px-2 py-1 shadow"
      >
        <FontAwesomeIcon icon={faEdit} className="mr-1" />
        Edit
      </button>

      <h1 className="text-3xl font-bold mt-10">{name}</h1>
      <img
        src={image}
        alt={name}
        className="mt-4 w-full h-64 object-cover rounded"
      />
      <p className="mt-2">{description}</p>

      {isMobile ? (
        <details>
          <summary className="font-bold mt-4">More Details</summary>
          <div>
            <h2 className="text-2xl">Health Status: {health || 'Unknown'}</h2>
            <h3 className="text-lg">Watering Reminders:</h3>
            <div className="flex flex-wrap space-x-4 mb-2">
              {wateringReminders.days.map((day) => (
                <span key={day} className="bg-green-300 rounded-full px-3 py-1 text-sm text-black mr-2">
                  {day}
                </span>
              ))}
            </div>
            <h4 className="text-lg">Time: {wateringReminders.time || 'N/A'}</h4>
          </div>
        </details>
      ) : (
        <div className="mt-4">
          <h2 className="text-2xl">Health Status: {health || 'Unknown'}</h2>
          <h3 className="text-lg">Watering Reminders:</h3>
          <div className="flex flex-wrap space-x-4 mb-2">
            {wateringReminders.days.map((day) => (
              <span key={day} className="bg-green-300 rounded-full px-3 py-1 text-sm text-black mr-2">
                {day}
              </span>
            ))}
          </div>
          <h4 className="text-lg">Time: {wateringReminders.time || 'N/A'}</h4>
        </div>
      )}
    </div>
  );
};

export default PlantDetails;
