// src/App.js
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {OfflineProvider} from './components/OfflineContext';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import AddPlant from './components/AddPlant';
import PlantDetails from './components/PlantDetails';
import EditPlant from './components/EditPlant'

const App = () => {
    return (
        <OfflineProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/add-plant" element={<AddPlant/>}/>
                    <Route path="/plant-details/:plantId" element={<PlantDetails/>}/>
                    <Route path="/edit-plant/:plantId" element={<EditPlant/>}/>
                </Routes>
            </Router>
        </OfflineProvider>
    );
};

export default App;
