import React, { useEffect, useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const uid = Cookies.get('uid');
    if (uid) {
      navigate('/dashboard'); // Redirect to Dashboard if cookie exists
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;
      Cookies.set('uid', uid, { expires: 7 });
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      alert('Login failed');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-100">
      <button
        onClick={() => navigate('/')}
        className="absolute top-4 left-4 flex items-center text-lg bg-white rounded px-2 py-1 shadow"
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
        Back
      </button>
      <h1 className="text-3xl font-bold mb-6">Login to Plantly</h1>
      <form onSubmit={handleLogin} className="space-y-4 w-full max-w-md px-4">
        <input
          className="p-2 border border-gray-300 rounded w-full"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="p-2 border border-gray-300 rounded w-full"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="flex items-center justify-center space-x-2 px-4 py-2 bg-green-600 text-white rounded w-full" type="submit">
          <FontAwesomeIcon icon={faUser} />
          <span>Login</span>
        </button>
      </form>
      <Link to="/" className="mt-4 text-green-600 underline">
        Back to Home
      </Link>
    </div>
  );
};

export default Login;
