// Offline.js
import React from 'react';
import { useOffline } from './OfflineContext'; // Import the context

const Offline = () => {
  const isOffline = useOffline(); // Get the offline status

  if (!isOffline) return null; // Don't render if online

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <h2 className="text-white text-2xl">You are offline. Please check your internet connection.</h2>
    </div>
  );
};

export default Offline;
