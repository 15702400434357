import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { auth } from '../firebase'; // Adjust the import based on your structure
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const uid = Cookies.get('uid');
    if (uid) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      Cookies.set('uid', user.uid);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-100">
      <h1 className="text-4xl font-bold mb-6">Welcome to Plantly</h1>
      <div className="flex flex-col space-y-4 w-full max-w-md px-4">
        <Link to="/Login">
          <button className="flex items-center justify-center space-x-2 px-4 py-2 bg-green-600 text-white rounded w-full">
            <FontAwesomeIcon icon={faUser} />
            <span>Login</span>
          </button>
        </Link>
        <Link to="/Register">
          <button className="flex items-center justify-center space-x-2 px-4 py-2 bg-green-600 text-white rounded w-full">
            <FontAwesomeIcon icon={faUserPlus} />
            <span>Register</span>
          </button>
        </Link>
        <button
          onClick={handleGoogleSignIn}
          className="flex items-center justify-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded w-full"
        >
          <FontAwesomeIcon icon={faGoogle} />
          <span>Sign in with Google</span>
        </button>
      </div>
    </div>
  );
};

export default Home;
