import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from './LoadingSpinner'; // Import the loading spinner
import Offline from './Offline'; // Import the Offline component
import { useOffline } from './OfflineContext'; // Import the offline context

const EditPlant = () => {
  const { plantId } = useParams();
  const navigate = useNavigate();
  const [plant, setPlant] = useState(null);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [health, setHealth] = useState('');
  const [wateringReminders, setWateringReminders] = useState({ days: [], time: '' });
  const [loading, setLoading] = useState(true); // Loading state
  const isOffline = useOffline(); // Get the offline status

  useEffect(() => {
    const fetchPlant = async () => {
      const plantDoc = doc(db, 'plants', plantId);
      const docSnap = await getDoc(plantDoc);
      if (docSnap.exists()) {
        setPlant(docSnap.data());
        setName(docSnap.data().name);
        setHealth(docSnap.data().health);
        setWateringReminders(docSnap.data().wateringReminders);
      } else {
        console.log('No such document!');
      }
      setLoading(false); // Set loading to false after fetching
    };

    fetchPlant();
  }, [plantId]);

  const handleDayChange = (day) => {
    setWateringReminders((prev) => {
      const newDays = prev.days.includes(day)
        ? prev.days.filter((d) => d !== day)
        : [...prev.days, day];
      return { ...prev, days: newDays };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const plantRef = doc(db, 'plants', plantId);
    await updateDoc(plantRef, {
      name,
      health,
      wateringReminders,
      image: image ? await convertImageToBase64(image) : plant.image,
    });
    navigate(`/plant-details/${plantId}`);
  };

  const convertImageToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  if (loading) return <LoadingSpinner />; // Show loading spinner
  if (isOffline) return <Offline />;

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-100 p-4">
      <h1 className="text-3xl font-bold mb-6">Edit Plant</h1>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
        <button
          onClick={() => navigate(`/plant-details/${plantId}`)}
          className="absolute top-4 left-4 p-2 bg-white rounded shadow hover:bg-gray-200"
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </button>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Plant Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="image">
            Plant Image
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="health">
            Health Status
          </label>
          <input
            type="text"
            id="health"
            value={health}
            onChange={(e) => setHealth(e.target.value)}
            required
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>

        <div className="mb-4">
          <h3 className="font-bold mb-2">Watering Reminders</h3>
          <div className="flex flex-wrap space-x-4 mb-2">
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
              <label key={day} className="flex items-center">
                <input
                  type="checkbox"
                  checked={wateringReminders.days.includes(day)}
                  onChange={() => handleDayChange(day)}
                  className="mr-2"
                />
                <span className="text-gray-700">{day}</span>
              </label>
            ))}
          </div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="time">
            Time
          </label>
          <input
            type="time"
            value={wateringReminders.time}
            onChange={(e) => setWateringReminders({ ...wateringReminders, time: e.target.value })}
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>

        <button type="submit" className="mt-4 bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700">
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditPlant;
