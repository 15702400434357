import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
//import { getMessaging } from 'firebase/messaging';
//import { getToken } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyAG17JDnvO6NOBTSESb-NmyQnKyaSD2eGs",
    authDomain: "as-plantly.firebaseapp.com",
    projectId: "as-plantly",
    storageBucket: "as-plantly.appspot.com",
    messagingSenderId: "618009014776",
    appId: "1:618009014776:web:e070a05e87cf1bbdf8660d"
};

//const requestNotificationPermission = async () => {
//  try {
//    const token = await getToken(messaging, {
//      vapidKey: 'BB7VpdNmL_U5uWJqPDiCDMtK9lKuIQpArEcU8z_aNIm0_Fo',
//    });
//    if (token) {
//      console.log('FCM Token:', token);
//      // Send this token to your server for storing and sending notifications.
//    } else {
//      console.log('No registration token available. Request permission to generate one.');
//    }
//  } catch (error) {
//    console.error('An error occurred while retrieving token. ', error);
//  }
//};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
//const messaging = getMessaging(app);

export {auth, db};
