import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const AddPlant = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [health, setHealth] = useState('');
  const [wateringReminders, setWateringReminders] = useState({
    days: [],
    time: '',
  });

  const navigate = useNavigate();

  const handleDayChange = (day) => {
    setWateringReminders((prev) => {
      const newDays = prev.days.includes(day)
        ? prev.days.filter((d) => d !== day)
        : [...prev.days, day];
      return { ...prev, days: newDays };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get uid from cookies
    const uid = document.cookie.split('; ').find(row => row.startsWith('uid=')).split('=')[1];

    // Convert the image to base64
    let base64Image = '';
    if (image) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = async () => {
        base64Image = reader.result; // base64 string
        try {
          await addDoc(collection(db, 'plants'), {
            name,
            image: base64Image, // send the base64 image
            health,
            wateringReminders,
            UserId: uid, // Save the UserId
          });
          navigate('/dashboard');
        } catch (error) {
          console.error('Error adding plant: ', error);
        }
      };
    } else {
      try {
        await addDoc(collection(db, 'plants'), {
          name,
          image: '', // or handle accordingly
          health,
          wateringReminders,
          UserId: uid, // Save the UserId
        });
        navigate('/dashboard');
      } catch (error) {
        console.error('Error adding plant: ', error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-100 p-4">
      <h1 className="text-3xl font-bold mb-6">Add a New Plant</h1>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
        <button
          onClick={() => navigate('/dashboard')}
          className="absolute top-4 left-4 p-2 bg-white rounded shadow hover:bg-gray-200"
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </button>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Plant Name
          </label>
          <input
            type="text"
            id="name"
            placeholder="Enter plant name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="image">
            Plant Image
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="health">
            Health Status
          </label>
          <input
            type="text"
            id="health"
            placeholder="Enter health status"
            value={health}
            onChange={(e) => setHealth(e.target.value)}
            required
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>

        <div className="mb-4">
          <h3 className="font-bold mb-2">Watering Reminders</h3>
          <div className="flex flex-wrap space-x-4 mb-2">
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
              <label key={day} className="flex items-center">
                <input
                  type="checkbox"
                  checked={wateringReminders.days.includes(day)}
                  onChange={() => handleDayChange(day)}
                  className="mr-2"
                />
                <span className="text-gray-700">{day}</span>
              </label>
            ))}
          </div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="health">
            Time
          </label>
          <input
            type="time"
            value={wateringReminders.time}
            onChange={(e) => setWateringReminders({ ...wateringReminders, time: e.target.value })}
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring focus:ring-green-300"
          />
        </div>

        <button type="submit" className="mt-4 bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700">
          Add Plant
        </button>
      </form>
    </div>
  );
};

export default AddPlant;
