import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import LoadingSpinner from './LoadingSpinner';
import Cookies from "js-cookie";

const Dashboard = () => {
  const [plants, setPlants] = useState([]);
  const [loading, setLoading] = useState(true); // Define loading state
  const [showConfirm, setShowConfirm] = useState(false); // State for confirmation dialog
  const navigate = useNavigate()
  const uid = Cookies.get('uid');

  useEffect(() => {

    if (!uid) {
      navigate('/')
    }
    const fetchPlants = async () => {
      // Get uid from cookies
      const uid = document.cookie.split('; ').find(row => row.startsWith('uid=')).split('=')[1];

      // Query plants based on UserId
      const plantCollection = collection(db, 'plants');
      const plantQuery = query(plantCollection, where('UserId', '==', uid));
      const plantSnapshot = await getDocs(plantQuery);
      const plantList = plantSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPlants(plantList);
      setLoading(false); // Set loading to false when data is fetched
    };

    fetchPlants();
  }, [navigate, uid]);

  const handleSignOut = () => {
    // Clear the 'uid' cookie
    document.cookie = "uid=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    // Redirect to home page
    window.location.href = "/";
  };

  const handleConfirmSignOut = () => {
    setShowConfirm(true);
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="relative p-4 bg-green-100 min-h-screen">
      <button
        onClick={handleConfirmSignOut}
        className="absolute top-4 right-4 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 z-10"
      >
        Sign Out
      </button>

      <h1 className="text-3xl font-bold">Your Plants</h1>
      <Link to="/add-plant">
        <button className="mt-4 bg-green-600 text-white px-4 py-2 rounded">
          Add Plant
        </button>
      </Link>

      {showConfirm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <div className="bg-white p-6 rounded shadow-lg z-30">
            <h2 className="text-lg font-bold">Are you sure you want to sign out?</h2>
            <div className="mt-4">
              <button
                onClick={handleSignOut}
                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
              >
                Yes
              </button>
              <button
                onClick={handleCancel}
                className="ml-2 bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 ${showConfirm ? 'pointer-events-none opacity-50' : ''}`}>
        {plants.map((plant) => (
          <Link key={plant.id} to={`/plant-details/${plant.id}`} className="transition-transform transform hover:scale-105 p-4 bg-white rounded shadow">
            <img src={plant.image} alt={plant.name} className="h-32 w-full object-cover rounded" />
            <h2 className="text-lg font-bold mt-2">{plant.name}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
